var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sceneryList"},[_c('el-breadcrumb',{attrs:{"separator":"/"}},[_c('el-breadcrumb-item',[_vm._v("景区列表")])],1),_c('div',{staticClass:"search"},[_c('span',[_vm._v("所属分类:")]),_c('el-select',{attrs:{"clearable":"","placeholder":"请选择"},on:{"clear":function($event){return _vm.onClear(5)}},model:{value:(_vm.selectValue),callback:function ($$v) {_vm.selectValue=$$v},expression:"selectValue"}},_vm._l((_vm.options),function(item){return _c('el-option',{key:item.cateId,attrs:{"label":item.cateName,"value":item.cateId}})}),1),_c('el-select',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectValue),expression:"selectValue"}],attrs:{"placeholder":"请选择","clearable":""},on:{"clear":function($event){return _vm.onClear(1)}},model:{value:(_vm.list.cateId),callback:function ($$v) {_vm.$set(_vm.list, "cateId", $$v)},expression:"list.cateId"}},_vm._l((_vm.options1),function(item){return _c('el-option',{key:item.cateId,attrs:{"label":item.cateName,"value":item.cateId}})}),1),_c('span',[_vm._v("标签:")]),_c('el-input',{attrs:{"placeholder":"请输入分类名称"}}),_c('span',[_vm._v("上下架:")]),_c('el-select',{attrs:{"placeholder":"请选择","clearable":""},on:{"clear":function($event){return _vm.onClear(0)}},model:{value:(_vm.list.status),callback:function ($$v) {_vm.$set(_vm.list, "status", $$v)},expression:"list.status"}},_vm._l((_vm.statusOptions),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1),_c('span',[_vm._v("城市:")]),_c('el-input',{attrs:{"placeholder":"请输入城市名称"},model:{value:(_vm.list.city),callback:function ($$v) {_vm.$set(_vm.list, "city", $$v)},expression:"list.city"}}),_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.getSceneryList()}}},[_vm._v("查找")]),(
        _vm.$store.state.powerList.indexOf('scenery:scenery:list:insert') !== -1
      )?_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.$router.push('/addScenery')}}},[_vm._v("添加")]):_vm._e()],1),_c('el-table',{attrs:{"data":_vm.tableData,"border":""},on:{"selection-change":_vm.onSelectChange}},[_c('el-table-column',{attrs:{"align":"center","type":"selection"}}),_c('el-table-column',{attrs:{"align":"center","prop":"detp","label":"排序"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var row = ref.row;
return [(row.inputVisible)?_c('el-input',{ref:"saveTagInput",staticClass:"input-new-tag",attrs:{"size":"small"},on:{"input":_vm.onInput,"blur":function($event){return _vm.handleInputConfirm(row)}},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleInputConfirm(row)}},model:{value:(row.dept),callback:function ($$v) {_vm.$set(row, "dept", $$v)},expression:"row.dept"}}):_c('el-button',{staticClass:"button-new-tag",attrs:{"size":"small"},on:{"click":function($event){return _vm.onChangeSort(row)}}},[_vm._v(_vm._s(row.dept))])]}}])}),_c('el-table-column',{attrs:{"align":"center","prop":"sceneryId","label":"景区ID"}}),_c('el-table-column',{attrs:{"align":"center","prop":"showImg","label":"展示图"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var row = ref.row;
return [_c('el-image',{staticStyle:{"width":"60px","height":"60px"},attrs:{"src":row.showImg,"preview-src-list":[row.showImg]}})]}}])}),_c('el-table-column',{attrs:{"align":"center","prop":"sceneryName","label":"景区名称"}}),_c('el-table-column',{attrs:{"align":"center","prop":"cateName","label":"所属分类"}}),_c('el-table-column',{attrs:{"align":"center","prop":"label","label":"标签"}}),_c('el-table-column',{attrs:{"align":"center","prop":"strTj","label":"是否推荐"}}),_c('el-table-column',{attrs:{"align":"center","prop":"tel","label":"客服电话"}}),_c('el-table-column',{attrs:{"align":"center","prop":"city","label":"所在城市"}}),_c('el-table-column',{attrs:{"align":"center","prop":"strStatus","label":"状态"}}),_c('el-table-column',{attrs:{"align":"center","prop":"","label":"操作","width":"400"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var row = ref.row;
return [(
            _vm.$store.state.powerList.indexOf('scenery:scenery:list:up') !== -1
          )?_c('el-button',{directives:[{name:"show",rawName:"v-show",value:(row.strStatus == '下架'),expression:"row.strStatus == '下架'"}],attrs:{"size":"mini","type":"primary"},on:{"click":function($event){return _vm.changeRowSatus(row, 1)}}},[_vm._v("上架")]):_vm._e(),(
            _vm.$store.state.powerList.indexOf('scenery:scenery:list:down') !== -1
          )?_c('el-button',{directives:[{name:"show",rawName:"v-show",value:(row.strStatus == '上架'),expression:"row.strStatus == '上架'"}],attrs:{"size":"mini","type":"primary"},on:{"click":function($event){return _vm.changeRowSatus(row, 2)}}},[_vm._v("下架")]):_vm._e(),(
            _vm.$store.state.powerList.indexOf('scenery:scenery:list:update') !==
            -1
          )?_c('el-button',{attrs:{"size":"mini","type":"primary"},on:{"click":function($event){return _vm.$router.push(("/editScenery/" + (row.sceneryId)))}}},[_vm._v("编辑")]):_vm._e(),(
            _vm.$store.state.powerList.indexOf('scenery:scenery:list:delete') !==
            -1
          )?_c('el-button',{attrs:{"size":"mini","type":"primary"},on:{"click":function($event){return _vm.changeRowSatus(row, 0)}}},[_vm._v("删除")]):_vm._e(),(
            _vm.$store.state.powerList.indexOf('scenery:scenery:list:log') !== -1
          )?_c('el-button',{attrs:{"size":"mini","type":"primary"},on:{"click":function($event){return _vm.$router.push(("/sceneryLog/" + (row.sceneryId)))}}},[_vm._v("查看日志")]):_vm._e(),_c('el-button',{attrs:{"size":"mini","type":"primary"},on:{"click":function($event){return _vm.onShowAddress(row.sceneryId)}}},[_vm._v("查看页面地址")])]}}])})],1),_c('div',{staticClass:"btn"},[(_vm.$store.state.powerList.indexOf('scenery:scenery:list:up') !== -1)?_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.onChangeAllStatus(1)}}},[_vm._v("批量上架")]):_vm._e(),(
        _vm.$store.state.powerList.indexOf('scenery:scenery:list:down') !== -1
      )?_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.onChangeAllStatus(2)}}},[_vm._v("批量下架")]):_vm._e(),_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.onChangeTj(1)}}},[_vm._v("批量推荐")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.onChangeTj(0)}}},[_vm._v("批量取消推荐")]),(
        _vm.$store.state.powerList.indexOf('scenery:scenery:list:delete') !== -1
      )?_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.onChangeAllStatus(0)}}},[_vm._v("批量删除")]):_vm._e()],1),_c('el-pagination',{attrs:{"current-page":_vm.list.currentPage,"page-sizes":[5, 10, 15, 20],"page-size":_vm.list.pageSize,"layout":"total, sizes, prev, pager, next, jumper","total":_vm.pagination.total},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}}),_c('el-dialog',{attrs:{"title":"查看页面详情地址","visible":_vm.showAddress,"width":"35%"},on:{"update:visible":function($event){_vm.showAddress=$event}}},[_c('div',{staticStyle:{"display":"flex"},attrs:{"id":"address"}},[_c('div',{staticStyle:{"flex":"1"}},[_vm._v("前台详情地址:")]),_c('el-input',{attrs:{"readonly":"","id":"wrapper"},model:{value:(_vm.address),callback:function ($$v) {_vm.address=$$v},expression:"address"}}),_c('el-button',{attrs:{"type":"primary","icon":"el-icon-document-copy"},on:{"click":_vm.urla}},[_vm._v("一键复制")])],1),_c('div',{attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){_vm.showAddress = false}}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){_vm.showAddress = false}}},[_vm._v("确 定")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }