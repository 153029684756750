<template>
  <div class="sceneryList">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>景区列表</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="search">
      <span>所属分类:</span>
      <el-select
        @clear="onClear(5)"
        clearable
        v-model="selectValue"
        placeholder="请选择"
      >
        <el-option
          v-for="item in options"
          :key="item.cateId"
          :label="item.cateName"
          :value="item.cateId"
        >
        </el-option>
      </el-select>
      <el-select
        v-show="selectValue"
        v-model="list.cateId"
        placeholder="请选择"
        clearable
        @clear="onClear(1)"
      >
        <el-option
          v-for="item in options1"
          :key="item.cateId"
          :label="item.cateName"
          :value="item.cateId"
        >
        </el-option>
      </el-select>
      <span>标签:</span>
      <el-input placeholder="请输入分类名称"></el-input>
      <span>上下架:</span>
      <el-select
        v-model="list.status"
        placeholder="请选择"
        clearable
        @clear="onClear(0)"
      >
        <el-option
          v-for="item in statusOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <span>城市:</span>
      <el-input v-model="list.city" placeholder="请输入城市名称"></el-input>
      <el-button type="primary" @click="getSceneryList()">查找</el-button>
      <el-button
        type="primary"
        @click="$router.push('/addScenery')"
        v-if="
          $store.state.powerList.indexOf('scenery:scenery:list:insert') !== -1
        "
        >添加</el-button
      >
    </div>
    <el-table :data="tableData" border @selection-change="onSelectChange">
      <el-table-column align="center" type="selection"> </el-table-column>
      <el-table-column align="center" prop="detp" label="排序">
        <template slot-scope="{ row }">
          <el-input
            class="input-new-tag"
            v-if="row.inputVisible"
            v-model="row.dept"
            ref="saveTagInput"
            size="small"
            @input="onInput"
            @keyup.enter.native="handleInputConfirm(row)"
            @blur="handleInputConfirm(row)"
          >
          </el-input>
          <el-button
            v-else
            class="button-new-tag"
            size="small"
            @click="onChangeSort(row)"
            >{{ row.dept }}</el-button
          >
        </template>
      </el-table-column>
      <el-table-column align="center" prop="sceneryId" label="景区ID">
      </el-table-column>
      <el-table-column align="center" prop="showImg" label="展示图">
        <template slot-scope="{ row }">
          <el-image
            style="width: 60px; height: 60px"
            :src="row.showImg"
            :preview-src-list="[row.showImg]"
          ></el-image>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="sceneryName" label="景区名称">
      </el-table-column>
      <el-table-column align="center" prop="cateName" label="所属分类">
      </el-table-column>
      <el-table-column align="center" prop="label" label="标签">
      </el-table-column>
      <el-table-column align="center" prop="strTj" label="是否推荐">
      </el-table-column>
      <el-table-column align="center" prop="tel" label="客服电话">
      </el-table-column>
      <el-table-column align="center" prop="city" label="所在城市">
      </el-table-column>
      <el-table-column align="center" prop="strStatus" label="状态">
      </el-table-column>

      <el-table-column align="center" prop="" label="操作" width="400">
        <template slot-scope="{ row }">
          <el-button
            size="mini"
            v-show="row.strStatus == '下架'"
            type="primary"
            v-if="
              $store.state.powerList.indexOf('scenery:scenery:list:up') !== -1
            "
            @click="changeRowSatus(row, 1)"
            >上架</el-button
          >
          <el-button
            size="mini"
            v-show="row.strStatus == '上架'"
            type="primary"
            v-if="
              $store.state.powerList.indexOf('scenery:scenery:list:down') !== -1
            "
            @click="changeRowSatus(row, 2)"
            >下架</el-button
          >
          <el-button
            size="mini"
            type="primary"
            @click="$router.push(`/editScenery/${row.sceneryId}`)"
            v-if="
              $store.state.powerList.indexOf('scenery:scenery:list:update') !==
              -1
            "
            >编辑</el-button
          >
          <el-button
            size="mini"
            type="primary"
            @click="changeRowSatus(row, 0)"
            v-if="
              $store.state.powerList.indexOf('scenery:scenery:list:delete') !==
              -1
            "
            >删除</el-button
          >
          <el-button
            size="mini"
            type="primary"
            @click="$router.push(`/sceneryLog/${row.sceneryId}`)"
            v-if="
              $store.state.powerList.indexOf('scenery:scenery:list:log') !== -1
            "
            >查看日志</el-button
          >
          <el-button
            size="mini"
            type="primary"
            @click="onShowAddress(row.sceneryId)"
            >查看页面地址</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="btn">
      <el-button
        type="primary"
        @click="onChangeAllStatus(1)"
        v-if="$store.state.powerList.indexOf('scenery:scenery:list:up') !== -1"
        >批量上架</el-button
      >
      <el-button
        type="primary"
        @click="onChangeAllStatus(2)"
        v-if="
          $store.state.powerList.indexOf('scenery:scenery:list:down') !== -1
        "
        >批量下架</el-button
      >
      <el-button
        type="primary"
        @click="onChangeTj(1)"
        >批量推荐</el-button
      >
      <el-button
        type="primary"
        @click="onChangeTj(0)"
        >批量取消推荐</el-button
      >
      <el-button
        type="primary"
        @click="onChangeAllStatus(0)"
        v-if="
          $store.state.powerList.indexOf('scenery:scenery:list:delete') !== -1
        "
        >批量删除</el-button
      >
    </div>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="list.currentPage"
      :page-sizes="[5, 10, 15, 20]"
      :page-size="list.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pagination.total"
    >
    </el-pagination>
    <el-dialog
      title="查看页面详情地址"
      :visible.sync="showAddress"
      width="35%"
    >
      <div id="address" style="display: flex;">
        <div style="flex: 1;">前台详情地址:</div>
        <el-input
          v-model="address"
          readonly
          id="wrapper"
        ></el-input>
        <el-button
          type="primary"
          icon="el-icon-document-copy"
          @click="urla"
          >一键复制</el-button
        >
      </div>
      <div slot="footer">
        <el-button @click="showAddress = false">取 消</el-button>
        <el-button type="primary" @click="showAddress = false">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { selectAllByFatherId, sceneryCate,tj } from "../../api/sceneryCate";
import {
  sceneryList,
  changeSort,
  changeStatus,
  sceneryDetails,
} from "../../api/sceneryList";
export default {
  name: "sceneryList",
  data() {
    return {
      showAddress: false,
      address: "",
      tableData: [],
      pagination: {},
      selectValue: "",
      options: [],
      options1: [],
      ids: "",
      statusOptions: [
        {
          value: 1,
          label: "上架",
        },
        {
          value: 2,
          label: "下架",
        },
      ],
      list: {
        currentPage: 1,
        pageSize: 5,
        sceneryName: "",
        cateId: null,
        label: "",
        status: null,
        city: "",
      },
    };
  },
  watch: {
    selectValue: async function () {
      const { data } = await selectAllByFatherId({
        fatherId: this.selectValue,
      });
      this.$store.commit("setsceneryValue", this.selectValue);
      this.options1 = data.data;
    },
    "list.cateId": function () {
      this.$store.commit("setsceneryCateId", this.list.cateId);
    },
  },
  created() {
    this.selectValue = this.$store.state.sceneryValue;
    this.list.cateId = this.$store.state.sceneryCateId;

    this.getSceneryList();
    this.getScCateList();
  },
  methods: {
    onShowAddress(id) {
      this.address = "hotel/pages/ticketDetail/ticketDetail?travelId=" + id;
      this.showAddress = true;
    },
    urla() {
      this.$nextTick(() => {
       var inputElement =  document.getElementById("wrapper");
       //选中input框的内容
       inputElement.select();
        // 执行浏览器复制命令
       document.execCommand("Copy");
        this.$message({ message: "复制成功", type: "success" }); //此处为结合使用element-ui的Message 消息提示组件
      });
    },
    onInput(value) {
      var reg = /^[0-9]*[1-9][0-9]*$/;
      var reg2 = /^-[0-9]*[1-9][0-9]*$/;
      if (!reg.test(value) && !reg2.test(value)) {
        return this.$message({
          type: "warning",
          message: "请输入整数!",
        });
      }
    },
    onSelectChange(selection) {
      var arr = [];
      selection.map((item) => {
        arr.push(item.sceneryId);
      });
      this.ids = arr.join(",");
    },
    onChangeAllStatus(status) {
      if (this.ids == "") {
        return this.$message({
          type: "error",
          message: "请选择数据!",
        });
      }
      this.$confirm("你确定要批量改变这条数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data } = await changeStatus({
            status: status,
            ids: this.ids,
          });
          if (data.code == 0) {
            this.getSceneryList();
            this.$message({
              type: "success",
              message: data.msg,
            });
          } else {
            this.$message({
              type: "error",
              message: data.msg,
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    onChangeTj(status){
if (this.ids == "") {
        return this.$message({
          type: "error",
          message: "请选择数据!",
        });
      }
      this.$confirm("你确定要批量改变这条数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          console.log(1)
          const { data } = await tj({
            status: status,
            ids: this.ids,
          });
          if (data.code == 0) {
            this.getSceneryList();
            this.$message({
              type: "success",
              message: data.msg,
            });
          } else {
            this.$message({
              type: "error",
              message: data.msg,
            });
          }
        })
        .catch((err) => {
          console.log(err,'err')
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    async getScCateList() {
      const { data } = await selectAllByFatherId({
        fatherId: 0,
      });
      console.log(data, "jingqufenlei");
      this.options = data.data;
    },
    async getSceneryList() {
      const { data } = await sceneryList(this.list);
      console.log(data, "景区列表");
      data.list.map((item) => {
        item.inputVisible = false;
      });
      this.tableData = data.list;
      this.pagination = data.pagination;
    },
    onChangeSort(row) {
      console.log(row.inputVisible)
      row.inputVisible = true;
    },
    changeRowSatus(row, status) {
      this.$confirm("你确定要改变这条数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data } = await changeStatus({
            status: status,
            ids: [row.sceneryId].join(","),
          });
          if (data.code == 0) {
            this.getSceneryList();
            this.$message({
              type: "success",
              message: data.msg,
            });
          } else {
            this.$message({
              type: "error",
              message: data.msg,
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    async handleInputConfirm(row) {
      const { data } = await changeSort({
        sceneryId: row.sceneryId,
        dept: Number(row.dept),
      });
      console.log(data, "paixun");
      row.inputVisible = false;
    },
    onClear(status) {
      if (status == 0) {
        this.list.status = null;
      } else if (status == 1) {
        this.list.cateId = null;
      } else if (status == 5) {
        this.selectValue = null;
        this.list.cateId = null;
      }
    },
    handleSizeChange(num) {
      this.list.pageSize = num;
      this.getSceneryList();
    },
    handleCurrentChange(num) {
      this.list.currentPage = num;
      this.getSceneryList();
    },
  },
};
</script>
<style lang="less" scoped>
.sceneryList {
  .el-breadcrumb {
    height: 35px;
    border-bottom: 1px solid rgb(192, 191, 191);
  }
  .search {
    margin-top: 50px;
    span {
      margin: 0 10px 0 40px;
    }
    .el-input {
      width: 200px;
    }
    .el-button {
      margin-left: 20px;
    }
  }
  .el-table {
    margin-top: 50px;
    .el-table__body-wrapper {
      table {
        tbody {
          img {
            width: 80px;
            height: 80px;
          }
        }
      }
    }
  }
  .btn {
    margin-top: 50px;
    float: left;
  }
  .el-pagination {
    margin-top: 50px;
    float: right;
  }
  #address {
    display: flex;
    align-items: center;

    .el-input {
      width: 350px;
    }
  }
}
</style>